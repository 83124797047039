<template>
	<el-scrollbar wrap-class="scrollbar-wrapper">
		<el-menu :collapse="isCollapse" :collapse-transition="false" :default-active="activeMenu" :show-timeout="200" :unique-opened="true" active-text-color="#409EFF" background-color="#304156" mode="vertical" text-color="#bfcbd9">
			<div :show-timeout="2000" class="avatar-wrapper" v-if="sidebar.opened">
				<el-row @click.native="goToUserInfo">
					<el-col :span="24">
						<img :src="defaultAvatar + '?imageView2/1/w/80/h/80'" alt="头像" class="user-avatar">
					</el-col>
					<el-col :span="24" class="user-name"><span> 欢迎回来 {{ accountName }}</span></el-col>
				</el-row>
			</div>
			<sidebar-item :base-path="route.path" :item="route" :key="route.path" v-for="route in defaultRoutes"/>
		</el-menu>
	</el-scrollbar>
</template>

<script>
import {mapGetters} from 'vuex';
import SidebarItem from './SidebarItem';
import defaultAvatar from '@/assets/default/avatar.gif'; //默认头像
import {routes} from "@/router";

export default {
	data() {
		return {
			defaultAvatar, //配置默认头像
			defaultRoutes: routes
		}
	},
	components: {SidebarItem},
	computed: {
		activeMenu() {
			const route = this.$route;
			const {meta, path} = route;
			if (meta.activeMenu) {
				return meta.activeMenu;
			}
			return path;
		},
		...mapGetters([
			'sidebar'
		]),
		isCollapse() {
			return !this.sidebar.opened
		},
		accountName () {
			return this.$store.state.account
		}
	},
	methods: {
		redirectTo(params, newTab = true) {
			if (newTab) {
				const {href} = this.$router.resolve(params);
				window.open(href, '_blank');
			} else {
				this.$router.push(params);
			}
		},
		goToUserInfo() {
			//用户信息
			this.redirectTo({
				name: 'userManage'
			}, false);
		}
	}
}
</script>

<style lang="less" rel="stylesheet/less" scoped>
.avatar-wrapper {
	padding: 25px;
	display: block;
	position: relative;

	.user-name {
		color: white;
		font-weight: 600;
		margin-top: 8px;
		font-size: 13px;
	}

	.user-avatar {
		width: 64px;
		height: 64px;
		border-radius: 50%;
	}
}
</style>