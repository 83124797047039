import Vue from 'vue';
import ElementUI from 'element-ui';
import moment from 'moment';
import 'moment/locale/zh-cn';

import App from './App';
import router from './router/index';
import VueBus from 'vue-bus';
import store from './store/index';
import 'normalize.css/normalize.css'; // A modern alternative to CSS resets
import 'element-ui/lib/theme-chalk/index.css';
import '@/styles/index.less'; // global css

import '@/icons/index'; // icon
import '@/permission'; // permission control

Vue.use(VueBus); //兄弟组件通信的bus，使用this.$bus
Vue.use(ElementUI);
Vue.prototype.$moment = moment; //全局引入moment，直接使用this.$moment
Vue.config.productionTip = false;

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app');
