import router from './router/index';
import store from './store/index';
import NProgress from 'nprogress'; // progress bar
import 'nprogress/nprogress.css'; // progress bar style

// NProgress Configuration
NProgress.configure({
    showSpinner: false
});

// no redirect whitelist
const whiteList = ['/login'];

router.beforeEach((to, from, next) => {
    NProgress.start();
    if (store.state.token) {
        /* has token*/
        if (to.path === '/login') {
            next({path: '/'});
            NProgress.done()
        } else {
            next();
        }
    } else {
        /* has no token*/
        if (whiteList.indexOf(to.path) !== -1) { // 在免登录白名单，直接进入
            next();
        } else {
            next(`/login?redirect=${to.path}`); // 否则全部重定向到登录页
            NProgress.done();
        }
    }
});

router.afterEach(() => {
    NProgress.done(); // finish progress bar
});
