import axios from 'axios';
import {Message, MessageBox} from 'element-ui';
import store from '@/store/index';

// 错误处理
function checkError(error) {
    const response = error.response;
    let message = error.message;
    if (response.data && response.data.data) message += ` - ${response.data.data}`;

    const status = response.status;
    if (status === 401 && response.data.data === "invalid token...") {
        MessageBox.confirm('登录失效，点击重新登录', '提示', {
            confirmButtonText: '重新登录',
            showCancelButton: false,
            closeOnClickModal: false,
            showClose: false,
            closeOnPressEscape: false,
            type: 'warning'
        }).then(() => {
            store.dispatch('FedLogOut').then(() => {
                location.reload(); // 为了重新实例化vue-router对象 避免bug
            });
        });
    } else {
        switch (status) {
            case 401:
                message = '错误401，抱歉，请求需要授权';
                break;
            case 403:
                message = '错误403，抱歉，您没有权限';
                break;
            case 404:
                message = '错误404，抱歉，请求未找到';
                break;
            default:
                break;
        }
        Message({
            message,
            type: 'error',
            duration: 5 * 1000
        });
    }
}

// 创建axios实例
const service = axios.create({
    baseURL: process.env.VUE_APP_BASE_API, // api 的 base_url
    timeout: 60000, // 请求超时时间
    paramsSerializer(params) {
        const searchParams = new URLSearchParams();
        for (const key of Object.keys(params)) {
            const param = params[key];
            if (Array.isArray(param)) {
                for (const p of param) {
                    searchParams.append(key, p);
                }
            } else {
                searchParams.append(key, param);
            }
        }
        return searchParams.toString();
    }
});

// request拦截器
service.interceptors.request.use(config => {
    if (store.state.token) {
        config.headers['authorization'] = store.state.token; // 让每个请求携带自定义token 请根据实际情况自行修改
    }
    return config;
}, error => {
    // Do something with request error
    Promise.reject(error).then();
});

// response 拦截器
service.interceptors.response.use(response => {
    const status = response.status;
    if (status === 200) {
        if (response.config.url.split('/').includes('login')) {
            return response;
        }
        return response.data;
    }

    // 请求出错
    MessageBox.confirm('请求出错，是否重新登录？', '错误', {
        confirmButtonText: '重新登录',
        cancelButtonText: '取消',
        type: 'error'
    }).then(() => {
        store.dispatch('FedLogOut').then(() => {
            location.reload(); // 为了重新实例化vue-router对象 避免bug
        });
    });
}, error => {
    console.log(error);
    if (error.response) {
        checkError(error);
    } else {
        // 请求出错
        MessageBox.confirm(`请求出错：${error.message} 您可以尝试刷新本页或者重新登录`, '错误', {
            confirmButtonText: '重新登录',
            cancelButtonText: '取消',
            type: 'error'
        }).then(() => {
            store.dispatch('FedLogOut').then(() => {
                location.reload(); // 为了重新实例化vue-router对象 避免bug
            });
        });
    }
    return Promise.reject(error);
});

export default service;
