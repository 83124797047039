<template>
    <el-breadcrumb class="app-breadcrumb" separator="/">
        <transition-group name="breadcrumb">
            <template v-for="(item,index) in levelList">
                <el-breadcrumb-item :key="item.path" v-if="item.meta.title">
                    <span v-if="item.redirect==='noredirect'|| index===levelList.length-1" class="no-redirect">{{ item.meta.title }}</span>
                    <router-link v-else :to="item.redirect||item.path">{{ item.meta.title }}</router-link>
                </el-breadcrumb-item>
            </template>
        </transition-group>
    </el-breadcrumb>
</template>

<script>
import pathToRegexp from 'path-to-regexp';

export default {
    data() {
        return {
            levelList: null
        }
    },
    watch: {
        $route() {
            this.getBreadcrumb();
        }
    },
    created() {
        this.getBreadcrumb();
    },
    methods: {
        getBreadcrumb() {
            const {params} = this.$route;
            let matched = this.$route.matched.filter(item => {
                if (item.name) {
                    const toPath = pathToRegexp.compile(item.path);
                    item.path = toPath(params);
                    return true
                }
            });
            const first = matched[0];
            if (first && first.name !== 'video') {
                matched = [{path: '/video', meta: {title: '首页'}}].concat(matched);
            }
            this.levelList = matched;
        }
    }
}
</script>

<style rel="stylesheet/less" lang="less" scoped>
.app-breadcrumb.el-breadcrumb {
  display: inline-block;
  font-size: 14px;
  line-height: 50px;
  margin-left: 10px;

  .no-redirect {
    color: #97a8be;
    cursor: text;
  }
}
</style>
