import Vue from 'vue';
import VueRouter from 'vue-router';
import Layout from '@/views/Layout/Layout';

Vue.use(VueRouter);

export const routes = [
    {
        path: '/login',
        component: () => import('@/views/Login/Index'),
        hidden: true
    },
    {
        path: '/',
        component: () => import('@/views/Layout/Layout'),
        redirect: '/video',
        name: 'videoLogs',
        children: [{
            path: 'video',
            component: () => import('@/views/VideoManage/Index'),
            meta: {
                title: '首页',
                icon: 'home'
            }
        }]
    },
    {
        path: '/user',
        component: () => import('@/views/Layout/Layout'),
        redirect: '/user/index',
        name: 'userManage',
        hidden: true,
        children: [{
            path: 'index',
            component: () => import('@/views/UserManage/Index'),
            meta: {
                title: '用户中心',
                icon: 'home'
            }
        }]
    },
    {
        path: '/xigua',
        component: Layout,
        redirect: '/xigua/manage',
        name: 'xiguaChannel',
        alwaysShow: true,
        meta: {
            title: '西瓜视频',
            icon: 'retest',
        },
        children: [{
            path: 'manage',
            name: 'xiguaManage',
            component: () => import('@/views/XiGuaChannel/Index'),
            meta: {
                title: '频道管理',
                icon: 'retest'
            }
        }]
    },
    {
        path: '/youtube',
        component: Layout,
        redirect: '/youtube/manage',
        name: 'youtubeChannel',
        alwaysShow: true,
        meta: {
            title: 'YouTube',
            icon: 'category_find_icon'
        },
        children: [{
            path: 'manage',
            name: 'youtubeManage',
            component: () => import('@/views/YouTubeChannel/ChannelManage/Index'),
            meta: {
                title: '频道管理',
                icon: 'simulate'
            }
        }, {
            path: 'bind',
            name: 'oauthTokenBind',
            component: () => import('@/views/YouTubeChannel/OauthTokenBind/Index'),
            meta: {
                title: 'TOKEN绑定',
                icon: 'simulate'
            }
        }]
    },
    {
        path: '/task',
        component: Layout,
        redirect: '/task/index',
        name: 'taskManage',
        meta: {
            title: '任务',
            icon: 'retest',
        },
        children: [{
            path: 'index',
            name: 'taskIndex',
            component: () => import('@/views/TaskManage/Index'),
            meta: {
                title: '任务管理',
                icon: 'retest'
            }
        }]
    },
    {
        path: '/client-secret',
        component: Layout,
        redirect: '/client-secret/index',
        name: 'clientSecret',
        meta: {
            title: '密钥',
            icon: 'retest',
        },
        children: [{
            path: 'index',
            name: 'clientSecretIndex',
            component: () => import('@/views/ClientSecret/Index'),
            meta: {
                title: '密钥管理',
                icon: 'retest'
            }
        }]
    }
]

const router = new VueRouter({
    scrollBehavior: () => ({y: 0}),
    routes
});

export default router;
