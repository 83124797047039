import Vue from 'vue';
import Vuex from 'vuex';
import Cookies from 'js-cookie';
import {login} from '@/api/login';

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        sidebar: {
            opened: !+Cookies.get('sidebarStatus'),
            withoutAnimation: false
        },
        device: 'desktop',
        token: Cookies.get('token'),
        account: Cookies.get('account'),
    },
    getters: {
        sidebar: state => state.sidebar,
        device: state => state.device,
        token: state => state.token,
    },
    mutations: {
        TOGGLE_SIDEBAR: (state) => {
            if (state.sidebar.opened) {
                Cookies.set('sidebarStatus', 1);
            } else {
                Cookies.set('sidebarStatus', 0);
            }
            state.sidebar.opened = !state.sidebar.opened;
            state.sidebar.withoutAnimation = false;
        },
        CLOSE_SIDEBAR: (state, withoutAnimation) => {
            Cookies.set('sidebarStatus', 1);
            state.sidebar.opened = false;
            state.sidebar.withoutAnimation = withoutAnimation;
        },
        TOGGLE_DEVICE: (state, device) => {
            state.device = device;
        },
        SET_TOKEN: (state, token) => {
            state.token = token;
            Cookies.set('token', token);
        },
        SET_ACCOUNT: (state, account) => {
            state.account = account;
            Cookies.set('account', account);
        },
    },
    actions: {
        // 登录
        Login({commit}, userInfo) {
            const account = userInfo.account.trim();
            return new Promise((resolve, reject) => {
                login(account, userInfo.password).then(response => {
                    console.log(response);
                    const token = response.data.data['token'];
                    commit('SET_TOKEN', token);
                    commit('SET_ACCOUNT', account);
                    resolve();
                }).catch(error => {
                    reject(error);
                });
            });
        },
        // 前端 登出
        FedLogOut({commit}) {
            return new Promise(resolve => {
                commit('SET_TOKEN', '');
                Cookies.set('token', '');
                resolve();
            });
        }
    }
})
