import request from '@/utils/request';

//登陆
export function login(account, password) {
    return request({
        url: '/api/v1/login',
        method: 'post',
        data: {
            account,
            password
        }
    });
}

//修改密码 {account, password, confirmPassword}
export function changePassword({account, password, confirmPassword}) {
    return request({
        url: '/api/v1/user/password',
        method: 'put',
        data: {account, password, confirmPassword}
    });
}
