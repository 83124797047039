<template>
    <div :class="classObj" class="app-wrapper">
        <div @click="handleClickOutside" class="drawer-bg" v-if="device === 'mobile' && sidebar.opened"></div>
        <sidebar class="sidebar-container"/>
        <div class="main-container">
            <navbar/>
            <app-main/>
        </div>
    </div>
</template>

<script>
import {AppMain, Navbar, Sidebar} from './components'
import ResizeMixin from './mixin/ResizeHandler'

export default {
    name: 'Layout',
    components: {
        Navbar,
        Sidebar,
        AppMain
    },
    mixins: [ResizeMixin],
    computed: {
        sidebar() {
            return this.$store.state.sidebar
        },
        device() {
            return this.$store.state.device
        },
        classObj() {
            return {
                hideSidebar: !this.sidebar.opened,
                openSidebar: this.sidebar.opened,
                withoutAnimation: this.sidebar.withoutAnimation,
                mobile: this.device === 'mobile'
            }
        }
    },
    methods: {
        handleClickOutside() {
            this.$store.commit('CLOSE_SIDEBAR', {withoutAnimation: false})
        }
    }
}
</script>

<style rel="stylesheet/less" lang="less" scoped>
@import "../../styles/mixin";

.app-wrapper {
@include clearFix;
  position: relative;
  height: 100%;
  width: 100%;

  &.mobile.openSidebar {
    position: fixed;
    top: 0;
  }
}

.drawer-bg {
  background: #000;
  opacity: 0.3;
  width: 100%;
  top: 0;
  height: 100%;
  position: absolute;
  z-index: 999;
}
</style>
