<template>
    <section class="app-main">
        <transition mode="out-in" name="fade-transform">
            <router-view :key="key"/>
        </transition>
    </section>
</template>

<script>
    export default {
        name: 'AppMain',
        computed: {
            key() {
                return this.$route.fullPath
            }
        }
    }
</script>

<style lang="less" rel="stylesheet/less" scoped>
    .app-main {
        /*50 = navbar  */
        min-height: calc(100vh - 50px);
        position: relative;
        overflow: hidden;
    }
</style>
